@tailwind base;
@tailwind components;
@tailwind utilities;

/* Aggiungi questi stili */
.fixed {
  position: fixed;
}

.z-50 {
  z-index: 50;
}

.overflow-y-auto {
  overflow-y: auto;
}

.bg-white {
  background-color: white;
}

/* Dialog Styles */
.Dialog-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadeIn 150ms ease-out;
}

.Dialog-panel {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform-origin: center;
  animation: dialogShow 150ms ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes dialogShow {
  from {
    opacity: 0;
    transform: translate(0, -2rem);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Z-index stacking */
.z-dialog {
  z-index: 50;
}

.z-dialog-backdrop {
  z-index: 40;
}

/* Dialog transitions */
.dialog-enter {
  opacity: 0;
  transform: scale(0.95);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 150ms ease-out, transform 150ms ease-out;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 150ms ease-in, transform 150ms ease-in;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

/* Ensure text contrast */
.dark .text-gray-100 {
  color: rgba(243, 244, 246, 1);
}

.dark .bg-gray-800 {
  background-color: rgba(31, 41, 55, 1);
}

/* Aggiungi questi stili per la barra di stato fissa */
.status-bar-offset {
  padding-top: 4rem; /* Compensare l'altezza della barra di stato */
}

/* Assicurati che la barra di stato sia sempre sopra altri elementi ma sotto l'header */
.z-40 {
  z-index: 40;
}
